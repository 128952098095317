<template>
<!-- 诺兴优学 国外 -->
  <div class="about">
    <div class="nuox-about-header">
      <img src="http://39.104.95.82:8087/img/header/3.jpg" >
    </div>
    <div class="module-content module-content-news">
      <div class="nuox-breadcrumb">
        <span class="nuox-breadcrumb-item"><router-link tag="span" :to="$t('lang.headerMenus[0].pageSrc')">{{ $t("lang.headerMenus[0].name") }}</router-link></span>
        <span class="iconfont icon-dian"></span>
        <span class="nuox-breadcrumb-item" @click="returnList">{{ $t("lang.headerMenus[6].name") }}</span>
        <span class="iconfont icon-dian"></span>
        <span class="nuox-breadcrumb-item" @click="returnList">{{ $t("lang.headerMenus[6].childrens[1].name") }}</span>
        <span class="iconfont icon-dian" v-if="pageType=='detail'"></span>
        <span class="nuox-breadcrumb-item" v-if="pageType=='detail'&&$i18n.locale=='zh'" >正文</span>
        <span class="nuox-breadcrumb-item" v-if="pageType=='detail'&&$i18n.locale=='en'" >TEXT</span>
      </div>
      <div class="module-line"></div>
        <div :class="$i18n.locale=='en'?'module-nav-en':'module-nav'">
          <ul class="menu-vertical-nav" v-if="pageType=='list'">
            <li v-for="(item,index) in menus" :key="index" @click="checknav(item)">
              <div :class="'title '+[item.id==checkId?'open':'']" ><span v-html="item.name"></span><span :class="[item.id==checkId?'el-icon-arrow-down':'el-icon-arrow-right']+ ' icon-down'"></span></div>
            </li>
          </ul>
          <ul class="menu-vertical-nav" v-if="pageType=='detail'">
            <li v-for="(item,index) in menusChildren" :key="index" @click="checkChildrenNav(index)">
              <div :class="'title '+[index==childrenCheckIndex?'open':'']" ><span  v-if="$i18n.locale=='zh'" v-html="item.videoNameZh"></span><span  v-if="$i18n.locale=='en'" v-html="item.videoNameEn"></span><span :class="[index==childrenCheckIndex?'el-icon-arrow-down':'el-icon-arrow-right']+ ' icon-down'"></span></div>
            </li>
          </ul>
          <div class="nuox-advert">
              <img :src="item.imgSrc" v-for="(item,index) in adverts" :key="index" @click="advertTap(item)">
          </div>
        </div>
        <div class="nuox-module"  v-if="pageType=='list'">
          <div class="nuox-content-title">
            <div class="title-name">
              {{ $t("lang.headerMenus[6].childrens[1].name") }}
              <div class="xian"></div>
            </div>
          </div>
          <div class="nuox-center" style="margin:20px 0">
            <div class="search-input person-input">
              <i class="el-icon-search"></i>
              <input type="text" class="" v-model="searchStr" placeholder="" @change="search"/>
            </div>
         </div>
          <div class="nuox-module-cooperate" >
            <el-row v-if="news.length>0">
              <el-col :span="12">
                <div class="nuox-new-header-main nuox-new-header-main-yx" @click="seeNews(news[lbIndex.start])">
                  <img :src="news[lbIndex.start].imgSrc">
                  <div class="new-header-info">
                    <div class="new-header-time">{{news[lbIndex.start].date}}</div>
                    <div  v-if="$i18n.locale=='zh'" class="new-header-name" v-html="news[lbIndex.start].titleZh"></div>
                    <div  v-if="$i18n.locale=='en'" class="new-header-name" v-html="news[lbIndex.start].titleEn"></div>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="nuox-new-header-list nuox-new-header-list-yx">
                  <div v-for="(item,index) in news" :key="index">
                    <div class="new-header-item new-header-item-yx"  v-if="lbIndex.start<index&&index<lbIndex.end"  @click="seeNews(item)">
                      <div class="new-item-info">
                        <div v-if="$i18n.locale=='zh'"  class="nuox-news-item-title" v-html="item.titleZh"></div>
                        <div v-if="$i18n.locale=='en'"  class="nuox-news-item-title" v-html="item.titleEn"></div>
                        <div v-if="$i18n.locale=='zh'"  class="nuox-news-item-name nuox-news-item-name-yx" v-html="item.commentZh"></div>
                        <div v-if="$i18n.locale=='en'"  class="nuox-news-item-name nuox-news-item-name-yx" v-html="item.commentEn"></div>
                        <div class="nuox-news-item-time">{{item.date}}</div>
                      </div>
                      <div class="pic-cover">
                        <img :src="item.imgSrc">
                      </div>
                    </div>
                  </div>
                  
                </div>
              </el-col>
            </el-row>
            <div class="nuox-study-list-name">
              <img src="../assets/img/study/video.png"> <span>视频专区</span>
            </div>
            <div class="nuox-study-list">
                <div :class="currNewsIndex!=index?'nuox-study-col':'nuox-study-col-hx'"  v-for="(item,index) in newAlls" :key="index"  @mouseenter="enter"   @mouseover="over(index)"   @mousemove="move"   @mouseout="out"   @mouseleave="leave(index)">
                    <el-card :body-style="{ padding: '0px' }" v-if="currNewsIndex!=index">
                      <img :src="item.imgFSrc" class="image nuox-study-list-img">
                      <div style="padding: 14px;">
                        <div class="nuox-study-list-crad-title" v-if="$i18n.locale=='zh'" v-html="item.titleZh"></div>
                        <div class="nuox-study-list-crad-title" v-if="$i18n.locale=='en'" v-html="item.titleEn"></div>
                        <div class="nuox-study-list-crad-f-title" v-if="$i18n.locale=='zh'" v-html="item.titleFZh"></div>
                        <div class="nuox-study-list-crad-f-title" v-if="$i18n.locale=='en'" v-html="item.titleFEn"></div>
                      </div>
                    </el-card>
                    <div v-if="currNewsIndex==index" class="nuox-study-hx-card">
                      <el-image class="study-hx-img" :src="item.imgSrc"></el-image>
                      <div class="nuox-study-hx-card-content">
                          <div class="hx-card-content-t">
                            <span v-if="$i18n.locale=='zh'" v-html="item.titleZh"></span>
                            <span v-if="$i18n.locale=='en'" v-html="item.titleEn"></span>
                          </div>
                          <div class="hx-card-content-i">
                              <span v-if="$i18n.locale=='zh'" v-html="item.commentZh"></span>
                              <span v-if="$i18n.locale=='en'" v-html="item.commentEn"></span>
                          </div>
                          <div class="hx-card-content-b">
                            <el-button type="primary" round size="mini" @click="seeNews(item)">立即观看</el-button>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
             <div class="nuox-page-center">
              <el-pagination
                  :small="true"
                  :background="true"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="pageNum"
                  :page-sizes="[5]"
                  :page-size="pageCount"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="totalCount">
                </el-pagination>
              </div>
          </div>
        </div>
        <div class="nuox-module" v-if="pageType=='detail'">
            <div class="input_video">
            <video-player  class="video-player vjs-custom-skin"
                          ref="videoPlayer"
                          :playsinline="true"
                          :options="playerOptions"
            ></video-player>
            </div>
            <div class="nuox-article-title" v-if="$i18n.locale=='en'" v-html="itemVideo.titleEn" ></div>
            <div class="nuox-article-title" v-if="$i18n.locale=='zh'" v-html="itemVideo.titleZh" ></div>
            <div class="nuox-article-author" v-if="$i18n.locale=='en'" ><span v-html="itemVideo.date"></span><span v-html="itemVideo.authorEn"></span></div>
            <div class="nuox-article-author" v-if="$i18n.locale=='zh'" ><span v-html="itemVideo.date"></span><span v-html="itemVideo.authorZh"></span></div>
            <div class="nuox-article-content" v-if="$i18n.locale=='en'" v-html="itemVideo.contentEn"></div>
            <div class="nuox-article-content" v-if="$i18n.locale=='zh'" v-html="itemVideo.contentZh"></div>
        </div>
      <div class="module-line"></div>
       <modal :show="show" :title="title" @hideModal="hideModal" @submit="checkViewAuth" @closeDiv="closeDiv">
        <!-- <input v-model="checkCode" class="check-code-div" type="text" placeholder="请先输入观影码校验"> -->
         <el-input placeholder="请输入观影码" v-model="checkCode" type="text" class="check-code-div">
              <template slot="append">
                  <el-button type="primary" class="submitButton" round @click="checkViewAuth" style="background: #25C8CC;">确认</el-button>
              </template>
          </el-input>        
      </modal>
    </div>
  </div>
</template>
<script>
import modal from './modal.vue';
export default {
  components: {
      modal
  },
  computed:{
    menus:function(){
      return this.$t('lang.headerMenus[6].childrens');
    }
  },
  data:function() {
    return {
        checkId:62,
        checkChildrenId:0,
        childrenCheckIndex:0,//每节课的选中下标
        pageNum:1,
        pageCount:5,
        totalCount:0,
        adverts:[],
        newAlls:[],
        news:[],
        menusChildren:[],
        lbIndex:{start:0,end:4},
        currNewsIndex:-1,
        pageType:'list',
        itemVideo:{
          contentEn:'',
          contentZh:'',
          titleEn:'',
          titleZh:'',
          authorEn:'',
          authorZh:'',
          date:'',
        },
        searchStr:'',
        playerOptions:{
          playbackRates : [ 0.5, 1.0, 1.5, 2.0 ], //可选择的播放速度
                    autoplay : false, //如果true,浏览器准备好时开始回放。
                    muted : false, // 默认情况下将会消除任何音频。
                    loop : false, // 视频一结束就重新开始。
                    preload : 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language : 'zh-CN',
                    sources:[],
                    poster:"",
                    aspectRatio : '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid : true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    // width: document.documentElement.clientWidth,
                    notSupportedMessage : '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controlBar : {
                        timeDivider : true,//当前时间和持续时间的分隔符
                        durationDisplay : true,//显示持续时间
                        remainingTimeDisplay : false,//是否显示剩余时间功能
                        fullscreenToggle : true  //全屏按钮
                    }
        },
        show: false,
        title:"观影码",
        checkCode:''
    };
  },
  mounted(){
    var id = this.$route.query.id;
     if(id){
      this.pageType='detail';
      this.initInfoById(id);
     }else{
      this.init('GW',1);
      this.init('GW','');
     }
     this.advert();
    /*window.setInterval(function(){
      console.log("定时轮播");
    }, 3000);*/
  },
  watch:{
      pageType(val, oldVal){//普通的watch监听
        if(val=='list'){
            this.init('GW',1);
            this.init('GW','');
        }
      }
  },
  methods:{
    async init(type,videoTop){
      var that = this;
      var baseUrl = this.$baseURL;
      const { data: res } = await this.$http.get(
        "webSite/nuox_video?pageNum="+that.pageNum+"&pageSize="+that.pageCount+"&videoType="+type+"&search="+that.searchStr+"&videoTop="+videoTop
      );
     
      if(res.code==0){
         var result = res.data.data;
        var data = result.rows;
        that.totalCount = result.total;
          var newAlls = [];
          for(var key in data){
              var imgSrc = '';
              if(data[key].fmAttList&&data[key].fmAttList.length>0){
                imgSrc = baseUrl+data[key].fmAttList[0].attPath+data[key].fmAttList[0].attRealName
              }
              var imgFSrc = '';
              if(data[key].ffmAttList&&data[key].ffmAttList.length>0){
                imgFSrc = baseUrl+data[key].ffmAttList[0].attPath+data[key].ffmAttList[0].attRealName
              }
              var videoSrc = '';
              if(data[key].videoAttList&&data[key].videoAttList.length>0){
                videoSrc = baseUrl+data[key].videoAttList[0].attPath+data[key].videoAttList[0].attRealName
              }
              var new1 = {};
              new1.commentZh = that.$getHtmlToText(data[key].videoContactZh);
              new1.commentEn =  that.$getHtmlToText(data[key].videoContactEn);
              new1.titleZh =  data[key].videoNameZh;
              new1.titleEn =  data[key].videoNameEn;
              new1.titleFZh =  data[key].videoNameViceZh;
              new1.titleFEn =  data[key].videoNameViceEn;
              new1.date =  data[key].videoDate;
              new1.authorZh =  data[key].videoLecturerZh;
              new1.authorEn =  data[key].videoLecturerEn;
              new1.contentZh = data[key].videoContactZh;
              new1.contentEn = data[key].videoContactEn;
              new1.day = that.$moment(new1.date).format('DD');
              new1.month = that.$moment(new1.date).format('YYYY-MM');
              new1.imgSrc = imgSrc;
              new1.imgFSrc = imgFSrc;
               new1.childrens = data[key].childrenList;
              new1.videoSrc = videoSrc;
              new1.className = "nuox-study-col";
              newAlls.push(new1);
          }
          if(videoTop==1){
             that.news= newAlls;
          }else{
            that.newAlls= newAlls;
          }
      }
    },
    async initInfoById(id){
      var that = this;
      var baseUrl = this.$baseURL;
      const { data: res } = await this.$http.get(
        "webSite/nuox_video_by_id?id="+id
      );
      if(res.code==0){
        var data = res.data.data;
        var imgSrc = '';
            if(data.fmAttList&&data.fmAttList.length>0){
               imgSrc = baseUrl+data.fmAttList[0].attPath+data.fmAttList[0].attRealName
            }
        var imgFSrc = '';
              if(data.ffmAttList&&data.ffmAttList.length>0){
                imgFSrc = baseUrl+data.ffmAttList[0].attPath+data.ffmAttList[0].attRealName
              }
        var videoSrc = '';
            if(data.videoAttList&&data.videoAttList.length>0){
               videoSrc = baseUrl+data.videoAttList[0].attPath+data.videoAttList[0].attRealName
            }
            var new1 = {};
            new1.commentZh = that.$getHtmlToText(data.videoContactZh);
            new1.commentEn =  that.$getHtmlToText(data.videoContactEn);
           new1.titleZh =  data.videoNameZh;
              new1.titleEn =  data.videoNameEn;
              new1.titleFZh =  data.videoNameViceZh;
              new1.titleFEn =  data.videoNameViceEn;
              new1.date =  data.videoDate;
              new1.authorZh =  data.videoLecturerZh;
              new1.authorEn =  data.videoLecturerEn;
              new1.contentZh = data.videoContactZh;
              new1.contentEn = data.videoContactEn;
               new1.childrens = data.childrenList;
            new1.imgSrc = imgSrc;
            new1.imgFSrc = imgFSrc;
            new1.videoSrc = videoSrc;
            that.itemVideo=new1;
      }
    },
     hideModal() {
            // 取消弹窗回调
            this.show = false
    },

    submit() {
        // 确认弹窗回调
        this.show = false
    },
    closeDiv() {
        // 确认弹窗回调
        this.show = false
    },
    search(){
      this.init('GW','');
    },
    checknav(e){
      if(e.pageSrc){
          this.$router.push({ 
            path: e.pageSrc,
            query: {}
          })
      }
      this.checkId = e.id;
    },
    checkChildrenNav(index){
      this.childrenCheckIndex = index;
      this.itemLoad();
    },
    checknavchildren(item,item2){
      this.checkId=item.id;
      this.checkChildrenId=item2.id;
    },
    handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pageCount=val;
        this.init('GW','');
    },
    handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pageNum=val;
        this.init('GW','');
    },seeNews(e){
      this.menusChildren = e.childrens;
      this.childrenCheckIndex=0;
      this.show = true
      // this.pageType = 'detail';
      // this.itemLoad();
    },
    checkViewAuth(){
      if(!this.checkCode.trim()){
          this.$message({
            message:"请输入正确的观影码~",
            type:'warning'
          })
          return ;
      }
      let that = this
      http://127.0.0.1:8087/webSite/checkViewingCodeExpired?viewingCode=NXYC1454729837179568128
      this.$http.get(
        "webSite/checkViewingCodeExpired?viewingCode="+this.checkCode
      ).then(res => {
        let response = res.data
        if(response.data.result){
           this.$message({
            message:"校验成功，请观看视频~",
            type:'success'
          })
          setTimeout(function () {
            that.show=false
            that.pageType = 'detail';
            that.itemLoad();
          },1000)
        }else{
           this.$message({
            message:response.data.resultMsg,
            type:'error'
          })
        }
      });
    },
    itemLoad(){
      var index = this.childrenCheckIndex;
      var that = this;
      var baseUrl = this.$baseURL;
      var data = this.menusChildren[index];
        var imgSrc = '';
            if(data.fmAttList&&data.fmAttList.length>0){
               imgSrc = baseUrl+data.fmAttList[0].attPath+data.fmAttList[0].attRealName
            }
        var imgFSrc = '';
              if(data.ffmAttList&&data.ffmAttList.length>0){
                imgFSrc = baseUrl+data.ffmAttList[0].attPath+data.ffmAttList[0].attRealName
              }
        var videoSrc = '';
            if(data.videoAttList&&data.videoAttList.length>0){
               videoSrc = baseUrl+data.videoAttList[0].attPath+data.videoAttList[0].attRealName
            }
            var new1 = {};
            new1.commentZh = that.$getHtmlToText(data.videoContactZh);
            new1.commentEn =  that.$getHtmlToText(data.videoContactEn);
           new1.titleZh =  data.videoNameZh;
              new1.titleEn =  data.videoNameEn;
              new1.titleFZh =  data.videoNameViceZh;
              new1.titleFEn =  data.videoNameViceEn;
              new1.date =  data.videoDate;
              new1.authorZh =  data.videoLecturerZh;
              new1.authorEn =  data.videoLecturerEn;
              new1.contentZh = data.videoContactZh;
              new1.contentEn = data.videoContactEn;
               new1.childrens = data.childrenList;
            new1.imgSrc = imgSrc;
            new1.imgFSrc = imgFSrc;
            new1.videoSrc = videoSrc;
            that.itemVideo=new1;
      console.log(data);
      this.playerOptions.sources = [ {
                        type : "",
                        src : that.itemVideo.videoSrc//url地址
                    } ];
      this.playerOptions.poster=that.itemVideo.imgSrc;
    },returnList(){
      this.pageType = 'list';
    },enter(){
    },over(index){
      this.currNewsIndex = index;
    },move(){
    },out(){
    },leave(index){
      this.currNewsIndex = -1;
    },async advert(){//加载广告
      var that = this;
       var baseUrl = this.$baseURL;
      const { data: res } = await this.$http.get(
        "webSite/nuox_advert?pageNum=2&pageCount=3"
      );
      if(res.code==0){
        var data = res.data.data;
        var adverts =[];
        for(var key in data){
          var advert = {};
          var imgSrc = '';
              if(data[key].attList&&data[key].attList.length>0){
                imgSrc = baseUrl+data[key].attList[0].attPath+data[key].attList[0].attRealName
              }
          advert.imgSrc = imgSrc;
          advert.href = data[key].advertAddress;
          adverts.push(advert);
        }
        that.adverts=adverts;
      }
    },
  }
}
</script>
<style>
@import '../assets/css/about.css';
@import '../assets/css/news.css';
@import '../assets/css/study.css';
</style>